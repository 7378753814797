import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function About() {
  return (
    <Layout>
      <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>About</title>
          <link rel="canonical" href="http://mkrilanovich.com/about" />
        </Helmet>
        <h1>About Page</h1>
        <p>STUFF</p>
      </div>
    </Layout>
  )
}
